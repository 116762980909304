.event {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;


    .widget {
        position: absolute;
        top: 6vw;
        left: 1.8vw;
        width: 25vw;
        aspect-ratio: 3/1;
        background: var(--event-widget-gradient);
        border-radius: .75rem;
        overflow: hidden;
        display: flex;
        animation: rotateLeft 1.2s forwards;

        &.shown {
            animation: spaceInLeft .7s;

            .info {
                .name {
                    animation: slideRightReturn 1.1s forwards;
                }
            }
        }

        .info {
            width: 65%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            box-sizing: border-box;
            position: relative;

            .emb {
                height: 120%;
                position: absolute;
                right: -15%;
                top: 5%;
                transform: rotate(-5deg);
                opacity: .2;
            }

            .season-qty {
                padding: .5rem 1rem 1rem 1.2rem;
                font-weight: 600;
                color: #193f6a;
                text-transform: uppercase;
                font-size: .9rem;
            }

            .label {
                padding-left: 1.5rem;
                padding-bottom: .35rem;
                color: #fff;
                opacity: .5;
                font-weight: 300;
                text-transform: uppercase;
                font-size: .9rem;
            }

            .name {
                margin-left: -1.5rem;
                animation: slideRight .5s forwards;
                padding: .5rem 1.3rem .5rem 1rem;
                position: relative;
                display: inline-block;
                width: fit-content;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    background: var(--event-name-gradient);
                    border-radius: 10px;
                    transform: skewX(-10deg);
                }

                span {
                    position: relative;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: .9rem;
                }
            }
        }

        .picture {
            width: 35%;
            background: #fff;
            height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            overflow: hidden;
            padding-top: .5rem;
            box-sizing: border-box;

            img.person {
                height: 130%;
            }
        }
    }
}
