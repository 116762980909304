@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

body {
    margin: 0;
    font-family: 'Rubik', sans-serif;
    background-color: transparent;
}

.overlay {
    width: 100%;
    aspect-ratio: 16/9;
    position: relative;
    overflow: hidden;
    //background-image: url('./assets/backdrop.jpg');
    background-size: cover;
}
