.timer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .widget {
        position: absolute;
        width: 27vw;
        aspect-ratio: 10/1.1;
        top: 2vw;
        left: 2vw;
        display: flex;
        justify-content: space-between;
        gap: 2%;
        transition: all 0.4s linear;

        &.gone {
            opacity: 0;
            visibility: hidden;
        }

        .brand {
            width: 21%;
            position: relative;
            height: 90%;
            display: flex;
            align-items: center;
            justify-content: center;

            .content {
                position: relative;
                height: 100%;
                width: 90%;
                background-image: var(--timer-brand);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }

            &:before {
                background: var(--timer-bgd);
                position: absolute;
                left: -.3rem;
                right: 0;
                bottom: 0;
                top: 0;
                content: '';
                border-radius: 10px;
                transform: skewX(-10deg);
            }
        }

        .mean {
            display: flex;
            width: 60%;
            align-items: center;
            position: relative;
            height: 90%;
            color: #fff;

            &:before {
                background: var(--timer-bgd);
                position: absolute;
                left: -.3rem;
                right: 0;
                bottom: 0;
                top: 0;
                content: '';
                border-radius: 10px;
                transform: skewX(-10deg);
            }

            .team {
                width: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                box-sizing: border-box;

                .colors {
                    position: absolute;
                    left: 0;
                    height: 2vw;
                    aspect-ratio: 1/5;
                    display: flex;
                    flex-direction: column;
                    border-radius: 12%;
                    transform: skewX(-10deg);
                    overflow: hidden;
                    opacity: .7;

                    span {
                        height: 50%;
                    }
                }

                &:first-child {
                    padding-left: 3%;

                    .colors {
                        border-top-left-radius: 20%;
                        border-bottom-left-radius: 20%;
                    }
                }

                &:last-child {
                    padding-right: 5%;

                    .colors {
                        left: unset;
                        right: 8%;
                        border-top-right-radius: 20%;
                        border-bottom-right-radius: 20%;
                    }
                }
            }

            .score {
                display: flex;
                width: 40%;
                position: relative;
                align-items: center;
                gap: 6%;

                &>div {
                    width: 50%;
                    height: 3.5vw;
                    background: var(--timer-score-bgd);
                    border-radius: 10%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.5rem;
                    font-weight: 600;
                }
            }
        }

        .time {
            width: 19%;
            height: 90%;
            position: relative;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .4s ease-in-out;
            visibility: hidden;
            opacity: 0;

            &.shown {
                opacity: 1;
                visibility: visible;
            }

            &:before {
                background: #000;
                position: absolute;
                left: -.3rem;
                right: 0;
                bottom: 0;
                top: 0;
                content: '';
                border-radius: 10px;
                transform: skewX(-10deg);
            }

            span {
                position: relative;
                font-weight: 600;
            }
        }
    }
}
