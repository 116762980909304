.standings {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &.gone {

        .widget {

            .backdrop {
                animation: slideLeft .6s forwards;
                margin-left: -20%;
            }

            .body {

                .widget-row {
                    animation: rotateRight 1s forwards;
                }
            }
        }
    }

    .widget {
        position: absolute;
        bottom: 3vw;
        left: 3vw;

        .backdrop {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--glass-panel-gradient);
            border-radius: .5rem;
            animation: slideLeftReturn .6s;
        }

        .body {
            position: relative;

            .widget-row {
                height: 2rem;
                padding-left: 1rem;
                display: flex;
                align-items: center;
                color: #fff;
                justify-content: space-between;
                animation: perspectiveUpReturn 1.2s;

                &.highlight {
                    background: var(--highlight-bgd) !important;

                    .values {
                        background: var(--highlight-bgd) !important;
                    }
                }

                &:nth-child(even) {
                    background: rgba(0, 0, 0, .12);

                    .values {
                        background: var(--widget-row-even);
                    }
                }

                .mean {
                    display: flex;
                    align-items: center;

                    .pos {
                        width: 1.4rem;
                    }

                    .emblem {
                        margin-right: .7rem;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        img {
                            width: 1.4rem;
                        }
                    }

                    .name {
                        font-weight: 500;
                        padding-right: .7rem;
                    }
                }

                .values {
                    display: flex;
                    width: 2.5rem;
                    height: 100%;
                    align-items: center;
                    font-weight: 600;
                    background: var(--widget-values-color);
                    justify-content: center;
                }
            }
        }
    }
}
