.roster {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: swap .7s;

    &.gone {
        animation: vanishOut .7s .3s forwards;

        .panel {

            .lineup {
                animation: holeOut 1.1s forwards;
            }

            .top {

                .emblem {
                    animation: slideRight .8s forwards;
                }

                .team {
                    animation: slideLeft .8s forwards;
                }
            }
        }
    }

    .panel {
        width: 45vw;
        height: 45vw;
        box-sizing: border-box;
        background: var(--glass-panel-gradient);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 4vw;
        justify-content: center;
        overflow: hidden;

        .top {

            .emblem {
                position: absolute;
                top: -7vw;
                right: -5vw;
                opacity: .45;
                animation: slideRightReturn .8s;

                img {
                    width: 25vw;
                }
            }

            .team {
                font-size: 1.8rem;
                text-transform: uppercase;
                color: #fff;
                font-weight: 500;
                padding-bottom: 4rem;
                padding-top: 4rem;
                animation: slideLeftReturn .8s;
            }
        }

        .bench {
            box-sizing: border-box;
            min-height: 5vw;
            padding-bottom: 2vw;

            .title {
                color: #fff;
                opacity: .4;
                text-transform: uppercase;
                font-weight: 600;
            }

            .list {
                font-size: .9rem;
                margin-top: .5rem;
                color: #fff;
                opacity: .7;
                font-weight: 500;
            }
        }

        .lineup {
            width: 100%;
            aspect-ratio: 1.35;
            position: relative;
            animation: slideInLeft .9s forwards;

            .pitch {
                position: absolute;
                opacity: .5;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: #fff;
                -webkit-mask-image: url('./football.svg');
                -webkit-mask-size: contain;
                -webkit-mask-repeat: no-repeat;
            }

            .lines {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: flex;
                flex-direction: column;
                padding-top: 1rem;

                .lineup-row {
                    display: flex;
                    height: 25%;
                    box-sizing: border-box;
                    justify-content: space-around;
                    align-items: center;

                    .lineup-row-item {
                        height: 100%;
                        margin: 0 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        position: relative;

                        .num {
                            position: absolute;
                            right: -.2rem;
                            top: -.2rem;
                            font-size: 1.2rem;
                            opacity: 0.85;
                            font-weight: 600;
                            color: #fff;
                        }

                        .lineup-row-item_name {
                            position: absolute;
                            top: 4rem;
                            background: var(--lineup-row-name);
                            color: #fff;
                            font-weight: 500;
                            font-size: .7rem;
                            padding: .2rem .6rem .15rem;
                            text-transform: uppercase;
                            white-space: nowrap;
                        }

                        .lineup-row-item_img {
                            height: 4.5rem;
                            aspect-ratio: 1;
                            margin: 0;
                            text-align: center;
                            overflow: hidden;

                            img {
                                height: 135%;
                            }
                        }
                    }
                }
            }
        }
    }
}
