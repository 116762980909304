.overriden .cover {
    .curtain {
        background: none !important;
    }

    .brand {
        display: none !important;
    }

    .brand-panel {
        box-shadow: none !important;
    }
}

.cover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    .brand-panel {
        position: absolute;
        width: 30%;
        top: 0;
        bottom: 0;
        left: 35%;
        z-index: 5;
        box-shadow: 1px 1px 100px rgba(0, 0, 0, 0.1);
        animation: slideUpReturn 1s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .top {
            padding-top: 8vh;
            color: #fff;
            text-align: center;

            .tournament {
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 500;
            }

            .matchday {
                font-size: 1.1rem;
                font-weight: 500;
                margin-top: .3rem;
                opacity: .6;
                text-transform: uppercase;
            }
        }

        .bottom {
            padding-bottom: 8vh;
            color: #fff;
            text-align: center;

            .time {
                font-size: 2.5rem;
                font-weight: 500;
            }

            .date {
                font-size: 1.25rem;
                font-weight: 500;
            }

            .location {
                padding: 1rem 1rem 0;
                font-size: .9rem;
                opacity: .6;
            }
        }

        .brand {
            //margin-bottom: 5vw;
            box-shadow: 1px 1px 50vw rgba(255, 255, 255, .7);
            z-index: 2;
            animation: vanishIn 2s;

            .brand-img {
                width: 7vw;
                height: 7vw;
                background: var(--brand-logo);
                background-size: cover;
                box-shadow: 1px 1px 50vw rgba(255, 255, 255, .7);
                animation: bounceRoot 3s infinite;
            }
        }
    }

    .curtain {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 50%;
        background: var(--curtain-left-gradient);
        animation: spaceInLeft .7s;

        .team {
            padding-right: 15vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            height: 100%;

            img {
                width: 15vw;
            }

            .text {
                color: #fff;
                text-align: center;
                font-weight: 600;

                .name {
                    font-size: 2.2rem;
                    padding: 0 2rem;
                }

                .city {
                    opacity: .6;
                    font-weight: 500;
                    margin-top: .2rem;
                }
            }
        }
    }

    .curtain:last-child {
        left: unset;
        right: 0;
        background: var(--curtain-right-gradient);
        animation: spaceInRight .7s;

        .team {
            padding-right: unset;
            padding-left: 15vw;
        }
    }

    &.gone {
        #override-video {
            display: none;
        }

        .curtain {
            animation: spaceOutLeft .8s forwards;
        }

        .curtain:last-child {
            animation: spaceOutRight .8s forwards;
        }

        .brand-panel {
            animation: holeOut 1s forwards;
        }
    }
}
