.overriden .splash {
    background: transparent;

    .brand {
        display: none;
    }
}

.splash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--splash-gradient);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: puffIn 2s;

    &.gone {
        animation: puffOut 2s forwards;

        .brand {
            animation: holeOut 2s forwards;
        }
    }

    .brand {
        margin-bottom: 5vw;
        box-shadow: 1px 1px 50vw rgba(255, 255, 255, .7);
        z-index: 2;
        animation: vanishIn 2s;

        .brand-img {
            width: 7vw;
            height: 7vw;
            background: var(--brand-logo);
            background-size: cover;
            box-shadow: 1px 1px 50vw rgba(255, 255, 255, .7);
            animation: bounceRoot 3s infinite;
        }
    }

    [aria-label="animation"] {
        position: absolute;
        z-index: 1;
        bottom: -15vw;
        left: -10vw;
        opacity: .1;
    }
}

@keyframes bounceRoot {
    0% {
        box-shadow: 1px 1px 50vw rgba(255, 255, 255, .9);
    }

    100% {
        box-shadow: 1px 1px 50vw rgba(255, 255, 255, .3);
    }
}
