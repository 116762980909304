@media screen and (max-width: 1080px) {
    html {
        font-size: 14px;
    }

    .timer .widget {
        width: 30vw;
        gap: 3%;
    }

    .timer .widget .brand:before, .timer .widget .mean:before, .timer .widget .time:before {
        border-radius: .5rem;
    }

    .timer .widget .mean .score > div {
        font-size: 1.2rem;
        border-radius: .35rem;
        height: 2.2rem;
    }

    .roster .panel .top .team {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .roster .panel .lineup .lines .lineup-row .lineup-row-item .lineup-row-item_img {
        height: 3.75rem;
    }

    .roster .panel .lineup .lines .lineup-row .lineup-row-item .lineup-row-item_name {
        top: 3.5rem;
    }

    .standings .widget .body .widget-row {
        padding-left: .75rem;
        height: 1.75rem;

        .mean {
            font-size: .9rem;

            .pos {
                width: 1.2rem;
            }

            .emblem {
                margin-right: .5rem;
            }

            .name {
                font-size: .85rem;
            }
        }
    }

    .cover .curtain .team .text .name {
        font-size: 1.8rem;
    }

    .cover .brand-panel .top {
        padding-top: 4vh;
    }

    .cover .brand-panel .bottom {
        padding-bottom: 4vh;
    }

    .cover .brand-panel .top .tournament {
        font-size: 1.2rem;
    }

    .event .widget .info .name {
        font-size: .85rem;
        padding: 0.35rem 1.1rem 0.3rem .8rem;
        margin-left: -1.1rem;
        white-space: nowrap;
    }

    .event .widget .info .season-qty {
        padding-bottom: .5rem;
    }

    .event .widget .picture {
        padding-top: .2rem;
    }

    .event .widget .info .label {
        padding-left: 1rem;
    }
}

@media screen and (max-width: 780px) {
    html {
        font-size: 12.5px;
    }

    .roster .panel .top .team {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .roster .panel .lineup .lines .lineup-row .lineup-row-item .lineup-row-item_img {
        height: 3.75rem;
    }

    .roster .panel .lineup .lines .lineup-row .lineup-row-item .lineup-row-item_name {
        top: 3.5rem;
    }

    .event .widget .info .name {
        padding: 0.3rem 1.1rem 0.25rem .8rem;
    }

    .event .widget .info .name span {
        font-size: .7rem;
    }

    .event .widget .info .label {
        font-size: .75rem;
        opacity: .8;
        padding-bottom: .25rem;
        padding-left: 1rem;
    }

    .event .widget .info .season-qty {
        font-size: .75rem;
        padding: 0.4rem 1rem .4rem 1rem;
    }
}
